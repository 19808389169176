import { useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import * as API from 'src/apis';
import { toYmdSlashHm } from 'src/utils/time';
import { DownloadIcon } from 'src/domains/root/commons/icons';
import BaseButton from 'src/domains/root/commons/buttons/Base';
import SettingIcon from 'src/domains/root/commons/layout/drawer/icons/SettingIcon';
import { HeadTitle } from 'src/shared/ui/HeadTitle';
import { Menu, MenuItem } from 'src/domains/root/commons/Menu';
import { type Period } from './PeriodInput';
import { GATEWAYS_ALL } from './ReportList';
import { useDeleteWorkspacesReports } from './useDeleteWorkspacesReports';
import ReportsDeleteDialog from './ReportsDeleteDialog';
import ReportsDownloadDialogContainer from './ReportsDownloadDialogContainer';

type ReportSelectType = 'delete' | 'download' | null;

export function ReportListTable({
  reports,
  reportsDelete,
  period,
  selectedGatewayName,
  searchTitle,
}: {
  reports: API.Report[];
  reportsDelete: ReturnType<typeof useDeleteWorkspacesReports>;
  period: Period;
  selectedGatewayName: string;
  searchTitle: string;
}) {
  const intl = useIntl();

  const isReportInPeriod = (report: API.Report): boolean => {
    return report.endAt >= period.beginAt && report.beginAt <= period.endAt;
  };

  const isSelectedGatewayName = (report: API.Report): boolean => {
    if (selectedGatewayName === GATEWAYS_ALL) {
      return true;
    }

    return report.gatewayName === selectedGatewayName;
  };

  const isSearchTitleIncluded = (report: API.Report): boolean => {
    return report.title.includes(searchTitle);
  };

  const filteredReports = reports
    .filter(isReportInPeriod)
    .filter(isSelectedGatewayName)
    .filter(isSearchTitleIncluded);

  const [selectedReport, setSelectedReport] = useState<{
    report: API.Report | null;
    type: ReportSelectType;
  }>({
    report: null,
    type: null,
  });

  const handleCloseDialog = () => {
    setSelectedReport({
      report: null,
      type: null,
    });
  };

  const handleSelectReport = (report: API.Report, type: ReportSelectType) => {
    setSelectedReport({
      report,
      type,
    });
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box display="flex" justifyContent="space-between" px={{ xs: 2, sm: 0 }}>
        <Box display="flex" alignItems="center" columnGap={1.5}>
          <HeadTitle>
            {intl.formatMessage({
              id: 'pages.Reports.reportListTable.searchResult',
            })}
          </HeadTitle>
          <Typography>
            {filteredReports.length}
            {intl.formatMessage({
              id: 'pages.Reports.reportListTable.counts',
            })}
          </Typography>
        </Box>
        <CreateNewReportButton />
      </Box>
      <Box
        bgcolor="common.white"
        borderRadius={1}
        border={{ xs: 'none', sm: '1px solid #828282' }}
        px={{ xs: 2, sm: 3 }}
        py={3}
        overflow={{ xs: 'auto', sm: 'unset' }}
      >
        <TableContainer>
          <Table>
            <ReportListTableHead />
            <TableBody>
              {filteredReports.length > 0 ? (
                filteredReports.map((report) => (
                  <TableRow key={report.reportId}>
                    <TableCell
                      align="center"
                      sx={{ fontSize: { xs: 13, sm: 14 } }}
                    >
                      <Typography fontSize="inherit" textAlign="right">
                        {toYmdSlashHm(DateTime.fromMillis(report.beginAt))}
                      </Typography>
                      ~
                      <Typography fontSize="inherit" textAlign="right">
                        {toYmdSlashHm(DateTime.fromMillis(report.endAt))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: { xs: 140, sm: 200 },
                        maxWidth: { xs: 200, sm: 320 },
                        fontSize: { xs: 13, sm: 16 },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {report.title}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: { xs: 160, sm: 200 },
                        maxWidth: 320,
                        fontSize: { xs: 13, sm: 16 },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {report.gatewayName}
                    </TableCell>
                    <TableCell align="center">
                      <ControlReprotButton
                        report={report}
                        onClick={handleSelectReport}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <DownloadReportButton
                        report={report}
                        onClick={handleSelectReport}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={5}
                    sx={{ pb: 0, borderBottom: 'unset' }}
                  >
                    {intl.formatMessage({
                      id: 'pages.Reports.reportListTable.noData',
                    })}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ReportsDeleteDialog
        report={selectedReport.report}
        reportsDelete={reportsDelete}
        open={selectedReport.type === 'delete'}
        onClose={handleCloseDialog}
      />
      {selectedReport.report && (
        <ReportsDownloadDialogContainer
          report={selectedReport.report}
          open={selectedReport.type === 'download'}
          onClose={handleCloseDialog}
        />
      )}
    </Box>
  );
}

function ReportListTableHead() {
  const intl = useIntl();

  return (
    <TableHead
      sx={{
        borderBottom: '2px solid #D2D2D2',
      }}
    >
      <TableRow>
        <TableCell
          sx={{
            pt: 1.25,
            pb: 3.25,
            minWidth: 100,
            width: 100,
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.period',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 1.25,
            pb: 3.25,
            minWidth: { xs: 140, sm: 200 },
            pl: { xs: 2.5, sm: 2 },
            pr: { xs: 6, sm: 2 },
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.reportTitle',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 1.25,
            pb: 3.25,
            minWidth: { xs: 140, sm: 200 },
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.gatewayName',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 1.25,
            pb: 3.25,
            minWidth: 70,
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.control.head',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 0,
            pb: 2,
            minWidth: 84,
            width: 84,
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.download',
          })}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function CreateNewReportButton() {
  const intl = useIntl();

  return (
    <Link to="new">
      <BaseButton
        variant="contained"
        sx={{
          px: 3,
          py: 7 / 8,
          backgroundColor: 'primary.main',
          color: 'common.white',
          boxShadow: 'none',

          '&:hover': {
            backgroundColor: 'nito.main',
            color: 'common.white',
            boxShadow: 'none',
          },

          '&:disabled': {
            backgroundColor: 'primary.main',
            color: 'common.white',
          },
        }}
      >
        {intl.formatMessage({
          id: 'pages.Reports.reportListTable.create',
        })}
      </BaseButton>
    </Link>
  );
}

function ControlReprotButton({
  report,
  onClick,
}: {
  report: API.Report;
  onClick: (report: API.Report, type: 'delete') => void;
}) {
  const intl = useIntl();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteReport = () => {
    onClick(report, 'delete');

    setAnchorEl(null);
  };

  const handleOpenReportDetailPage = () => {
    navigate(`/reports/${report.reportId}`);
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <SettingIcon />
      </IconButton>

      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleCloseMenu}>
        <MenuItem onClick={handleOpenReportDetailPage}>
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.control.detail',
          })}
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteReport}>
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.control.delete',
          })}
        </MenuItem>
      </Menu>
    </>
  );
}

function DownloadReportButton({
  report,
  onClick,
}: {
  report: API.Report;
  onClick: (report: API.Report, type: 'download') => void;
}) {
  const handleClick = () => {
    onClick(report, 'download');
  };

  return (
    <IconButton onClick={handleClick}>
      <DownloadIcon />
    </IconButton>
  );
}
